import React from 'react';
import { motion } from 'framer-motion';

const TokenComponent = ({ tokenId, isActivated, fetchTokenHolders }) => {
  // Generate a random delay for staggered animation
  const randomDelay = Math.random() * 2; // Random delay between 0 and 2 seconds

  // Animation variants for Framer Motion
  const pulseVariants = {
    active: {
      scale: [1, 1.05, 1], // Scale up and back to normal
      opacity: [1, 0.6, 1], // Slight fade
      transition: {
        duration: 5, // Animation duration
        repeat: Infinity, // Loop indefinitely
        ease: 'easeInOut', // Smooth easing
        delay: randomDelay, // Stagger the start with a random delay
      },
    },
    inactive: {
      scale: 1,
      opacity: 1,
    },
  };

  return (
    <motion.span
      key={tokenId}
      onClick={() => fetchTokenHolders(tokenId)}
      className={`d-inline-flex align-items-center justify-content-center rounded-circle ${
        isActivated ? 'bg-success' : 'bg-danger'
      }`}
      style={{
        width: '3.5rem',
        height: '3.5rem',
        cursor: 'pointer',
        color: 'white',
        fontSize: '0.8rem',
        fontWeight: 'bold',
      }}
      variants={pulseVariants} // Attach animation variants
      animate={isActivated ? 'active' : 'inactive'} // Toggle animation state
    >
      #{tokenId}
    </motion.span>
  );
};

export default TokenComponent;
