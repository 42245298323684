import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { Tabs, Tab, Container, Card, Spinner, Row, Col, Badge, Button, ProgressBar, Form, Navbar, Modal, Table, Pagination, Accordion } from 'react-bootstrap';
import './App.css';
import { io } from 'socket.io-client';
import { TbBrain } from "react-icons/tb";
import moment from 'moment';
import TokenComponent from './components/TokenComponent';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
const CONTRACT_ADDRESS = '0xb0974f12c7ba2f1dc31f2c2545b71ef1998815a4';


const App = () => {
  const [key, setKey] = useState(localStorage.getItem('currentTab') || 'holders');
  const [batches, setBatches] = useState([]);
  const [brainTokens, setBrainTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTokens, setLoadingTokens] = useState(true);
  const [loadingHolders, setLoadingHolders] = useState(true);
  const [darkMode, setDarkMode] = useState(true);
  const [holders, setHolders] = useState([]); // Holders of Brain NFTs
  const [batchSearch, setBatchSearch] = useState(''); // Search term for batches
  const [nftSearch, setNftSearch] = useState(''); // Search term for NFTs
  const [selectedToken, setSelectedToken] = useState(null);
  const [ERC20Address, setERC20Address] = useState(null); 
const [modalVisible, setModalVisible] = useState(false);
const [tokenHolders, setTokenHolders] = useState([]);
const [loadingContributors, setLoadingContributors] = useState(false);
const [poolContributors, setPoolContributors] = useState([]);
  const [selectedBatchIndex, setSelectedBatchIndex] = useState(null);
  const [loadingPool, setLoadingPool] = useState(false);
  const [currentPool, setCurrentPool] = useState(null);

    const hasFetchedPools = useRef(false); // Track if "Pools" tab has been initialized


const fetchPoolContributors = async (batchIndex) => {
  setLoadingContributors(true);
  setSelectedBatchIndex(batchIndex);

  console.log('batchIndex', batchIndex);

  try {
    const response = await fetch(`${API_URL}/pools/contributors/${batchIndex}`);
    if (!response.ok) {
      const text = await response.text();
      throw new Error(`Error fetching contributors: ${text}`);
    }
    const data = await response.json();
    console.log('data', data);
    setPoolContributors(data.contributors);
  } catch (error) {


        setPoolContributors([]);
    console.error(`Error fetching contributors for batch ${batchIndex}:`, error);
  } finally {
    setLoadingContributors(false);
  }
};
  
  
const fetchLatestPool = async (batchIndex) => {
  setLoadingPool(true);

  try {
    const response = await fetch(`${API_URL}/pools/contributors/${batchIndex}`);
    if (!response.ok) {
      const text = await response.text();
      throw new Error(`Error fetching contributors: ${text}`);
    }
    const data = await response.json();
    console.log('data', data);
    setCurrentPool(data);
  } catch (error) {


        setCurrentPool([]);
    console.error(`Error fetching contributors current pool:`, error);
  } finally {
    setLoadingPool(false);
  }
};
    
  
  
const fetchTokenHolders = async (tokenId) => {
  setLoadingHolders(true);
  try {
    setModalVisible(true);    
    const response = await fetch(`${API_URL}/holders/${tokenId}`);
    const data = await response.json();
    setTokenHolders(data);
    setSelectedToken(tokenId);
    setERC20Address(data.erc20Address);

  } catch (error) {
    console.error("Error fetching token holders:", error);
  } finally {
    setLoadingHolders(false);
  }
};

  
  
  
  
    const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
    localStorage.setItem('currentTab', selectedKey);
    };
  
  
  
  const shortenAddress = (address) => {
    return address.slice(0, 6) + '...' + address.slice(-4);
  };

  const pooledTokenIds = new Set(
    batches.filter(batch => batch.minted && batch.tokenId).map(batch => Number(batch.tokenId))
  );

  // Apply filtering
const filteredBatches = batches.filter((batch) =>
  batch.batchIndex.toString().includes(batchSearch)
);

const filteredNFTs = brainTokens.filter((token) =>
  token.tokenId.toString().includes(nftSearch)
);
  



  // useEffect(() => {

  //   const savedDarkMode = Cookies.get('darkMode');
  //   if (savedDarkMode) {
  //     setDarkMode(savedDarkMode === 'true');
  //   }
  // }, []);

  // useEffect(() => {
  //   Cookies.set('darkMode', darkMode, { expires: 365 });
  // }, [darkMode]);


 useEffect(() => {
    Cookies.set('activeTab', key); // Save the current tab in cookies

   if (key === 'pool' && !hasFetchedPools.current && filteredBatches.length > 0) {
      console.log('filteredBatches', filteredBatches);
     const latestBatchIndex = filteredBatches.length - 1
     console.log('latestBatchIndex', latestBatchIndex);
      setSelectedBatchIndex(latestBatchIndex); // Set the latest batch as selected
      fetchPoolContributors(latestBatchIndex); // Fetch contributors for the latest batch
      hasFetchedPools.current = true; // Mark that pools have been fetched
      console.log('fetching pool contributors for batch', latestBatchIndex);
    }
  }, [key, filteredBatches]);
  
  useEffect(() => {
    const socket = io(API_URL);
    socket.on('batchDataUpdated', (newData) => {
      setBatches((prevBatches) => {
        const combined = [...prevBatches, ...newData];
        const unique = [];
        const seen = new Set();
        for (const b of combined) {
          if (!seen.has(b.batchIndex)) {
            seen.add(b.batchIndex);
            unique.push(b);
          }
        }
        return unique.sort((a, b) => a.batchIndex - b.batchIndex);
      });
    });
    return () => {
      socket.disconnect();
    };
  }, []);


useEffect(() => {
  const fetchHolders = async () => {
    setLoadingHolders(true);
    try {
      const response = await fetch(`${API_URL}/holders`);
      if (!response.ok) {
        const text = await response.text();
        console.error('Server error:', text);
        throw new Error('Error fetching holders: ' + text);
      }

      const data = await response.json();
      console.log('Holders with ERC-20 Addresses:', data);

      setHolders(data);
    } catch (error) {
      console.error('Error fetching holders:', error);
    } finally {
      setLoadingHolders(false);
    }
  };

  fetchHolders();
}, []);



  useEffect(() => {
    const fetchBatches = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/batches`);
        if (!response.ok) {
          const text = await response.text();
          console.error('Server error:', text);
          throw new Error('Error fetching batches: ' + text);
        }
        const data = await response.json();
        setBatches(data.sort((a, b) => a.batchIndex - b.batchIndex));
        fetchLatestPool(data[data.length - 1].batchIndex);
      } catch (error) {
        console.error('Error fetching initial batches:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatches();
  }, []);



  useEffect(() => {
    const fetchBrainTokens = async () => {
      setLoadingTokens(true);
      try {
        const response = await fetch(`${API_URL}/brain-tokens`);
        if (!response.ok) {
          const text = await response.text();
          console.error('Server error:', text);
          throw new Error('Error fetching brain tokens: ' + text);
        }
        const tokensData = await response.json();
        console.log('tokensData', tokensData);
        setBrainTokens(tokensData.sort((a, b) => a.tokenId - b.tokenId));
      } catch (error) {
        console.error('Error fetching brain tokens:', error);
      } finally {
        setLoadingTokens(false);
      }
    };

    fetchBrainTokens();
  }, []);




  return (
    <div className={darkMode ? 'dark-mode' : ''}>
       <Navbar bg="white" expand="lg" className="shadow-sm">
          <Container>
            <Navbar.Brand>
             <TbBrain className="me-2" size={48} />
              Based Brains
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end py-4 text-center">

            <p className="mb-0">
            Follow {' '}
            <a href="https://x.com/basedbrains_xyz" target="_blank" rel="noopener noreferrer" className="text-primary">
              @basedbrains_xyz
            </a> on X
          </p>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
      <Container fluid className="min-vh-100 d-flex flex-column">
       
<Container className="pt-5">
  {loadingPool ? (
    <div className="text-center">
        <Spinner animation="border" variant="white" />
      <p className="mt-2 text-white">Loading the latest batch...</p>
    </div>
  ) : currentPool ? (
    <Card className="shadow-sm border-0 text-center">
      <Card.Body>
                  <div className="my-4">
                    
                    <div className="d-flex justify-content-center align-items-center">
          <h4 className="mb-0">
            Batch #{currentPool.batchIndex}{' '}
        
                    </h4>
                    
    {currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0) === 1000 ? (
              <Badge bg="success" text="light" className="ms-2">
                Pool Full
              </Badge>
            ) : (
              <Badge bg="warning" text="dark" className="ms-2">
                Pooling
              </Badge>
            )}

</div>
          <hr />
          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <h5>
                Total Contributions:{' '}
                {currentPool.contributors
                  .reduce((acc, curr) => acc + curr.totalContributed, 0)
                  .toFixed(2)}{' '}
                BCREDS
              </h5>
              <p className="mb-2">
                {currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0) < 1000
                  ? `${(1000 -
                      currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0)).toFixed(
                      2
                    )} BCREDS Needed`
                  : 'Fully Funded!'}
              </p>
              <ProgressBar
                now={currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0)}
                max={1000}
                          variant="success"
                          animated
                label={`${Math.floor(
                  (currentPool.contributors.reduce((acc, curr) => acc + curr.totalContributed, 0) / 1000) * 100
                )}%`}
                          className="mt-3"
                          style={{ height: '2rem', backgroundColor: 'black' }}
              />
            </Col>
          </Row>
          
        </div>
      </Card.Body>
    </Card>
  ) : (
    <div className="text-center">
      <p className="mt-2 text-white">
        No data available. Please try refreshing or check your connection.
      </p>
    </div>
  )}
</Container>

        <div className="text-white text-center pt-3">
          <p>Contract data is scanned every 30 mins.<br/>Last update {moment(brainTokens[0]?.updatedAt).format('DD MMM YYYY, h:mm:ss a')}</p>
        </div>
          
          <Container className="flex-grow-1 py-3">
          <Tabs defaultActiveKey="holders" activeKey={key}
            onSelect={handleTabSelect} id="controlled-tab-example">
            <Tab eventKey="holders" title={`Hive Status`}>
              <Card className="shadow-sm border-0">
              <Card.Body>
              <Card.Title className="fw-bold mb-4 d-flex justify-content-between align-items-center">
                          {brainTokens?.length} / 1024 minted ({holders.length} holders)
                          
                    <div className="d-flex gap-2">
<div className="badge-container">
  <span
    className="custom-badge text-white"
    style={{
      backgroundColor: '#28a745', // Green for "Activated"
      fontSize: '0.75rem', // Smaller font size
      padding: '0.5rem', // Smaller padding
      borderRadius: '0.5rem',
    }}
  >
    Activated
  </span>
  <span
    className="custom-badge text-white ms-2"
    style={{
      backgroundColor: '#dc3545', // Red for "Dormant"
      fontSize: '0.75rem',
      padding: '0.5rem', // Smaller padding
      borderRadius: '0.5rem',
    }}
  >
    Dormant
  </span>
</div>

                    </div>
                  </Card.Title>
                  <p>An activated Brain is one that has been minted and has an ERC-20 token balance.</p>

<hr/>
                {holders.length > 0 ? (
                holders.map((holder, index) => (
                  <div key={`holder-${holder.owner}-${index}`} className="mb-4">
                  <div className="mb-4">
                    <p>
                    <a
                      href={`https://etherscan.io/address/${holder.owner}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      {shortenAddress(holder.owner)}{' '}
                    </a>
                    </p>
                    <Badge pill bg="primary" text="light">{holder.count} {holder.count === 1 ? 'Brain' : 'Brains'}</Badge>
                    <Badge pill bg="secondary" text="light" className="ms-2">{((holder.count / 1024) * 100).toFixed(2)}%</Badge>
                    {holder.owner === '0xB0974F12C7BA2f1dC31f2C2545B71Ef1998815a4'
                    ? <Badge pill bg="warning" text="dark" className="ms-2">Contract Pool</Badge>
                    : null
                    }
                    {holder.owner === '0x8CAF5b4cb38BA53EECAa45Be4Ef9Cd9D7C0cc057'
                    ? <Badge pill bg="warning" text="dark" className="ms-2">BasedAI Team</Badge>
                    : null
                    }
                    {holder.owner === '0xE14767042159E5BD2bf16F81A0FE387ab153Fbb4'
                    ? <Badge pill bg="warning" text="dark" className="ms-2">The Legend FBB4</Badge>
                    : null
                    }
                  </div>
                  <span className="d-flex flex-wrap gap-2">
                    {holder.tokens.map((tokenId) => {
                      // Check if the token ID matches a pooled batch
                      
                      const isActivated = brainTokens[tokenId]?.isActivated;

  
                return (
<TokenComponent
        key={tokenId}
        tokenId={tokenId}
        isActivated={isActivated}
        fetchTokenHolders={fetchTokenHolders}
      />
                );
              })}

                                    {holder.owner === '0x8CAF5b4cb38BA53EECAa45Be4Ef9Cd9D7C0cc057' && (
<TokenComponent
        key={'1083'}
        tokenId={1083}
        isActivated={brainTokens['1023']?.isActivated || false}
        fetchTokenHolders={fetchTokenHolders}
                          />
                          


              )}

                      



            </span>
            <hr />
          </div>
        ))
      ) : (
        <p>No holders found.</p>
      )}
    </Card.Body>
  </Card>
</Tab>


      <Tab eventKey="pool" title={`Pools (${filteredBatches?.length})`}>
        <Card className="shadow-sm border-0">
          <Card.Body>
                  <Card.Title className="fw-bold mb-4">Total Pools {filteredBatches?.length}</Card.Title>
                  <Card.Text>
                    View the distribution of BCREDs in each pool.
                  </Card.Text>
            {loading ? (
              <div className="text-center">
                 <Spinner animation="border" variant="white" />
                <p className="mt-2 text-white">Loading pools...</p>
              </div>
            ) : filteredBatches.length > 0 ? (
                      <Accordion defaultActiveKey={filteredBatches[filteredBatches.length - 1].batchIndex.toString()}

                      >
                {filteredBatches.reverse().map((batch) => (
                  <Accordion.Item key={batch.batchIndex} eventKey={batch.batchIndex.toString()}>
                    <Accordion.Header
                      onClick={() => {
                        if (selectedBatchIndex !== batch.batchIndex) {
                          fetchPoolContributors(batch.batchIndex);
                        }
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span>Pool #{batch.batchIndex}</span>
                   
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {selectedBatchIndex === batch.batchIndex && loadingContributors ? (
                        <div className="text-center mt-3">
                             <Spinner animation="border" variant="white" />
                          <p className="mt-2 text-white">Loading contributors...</p>
                        </div>
                      ) : (
                        selectedBatchIndex === batch.batchIndex && (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Address</th>
                              <th>Total Contributed</th>
                            </tr>
                          </thead>
                          <tbody>
                           {poolContributors
          .sort((a, b) => b.totalContributed - a.totalContributed) // Sort by totalContributed (highest first)
  .map((contributor, idx) => (
    <tr key={idx}>
      <td>{idx + 1}</td>
      <td>
        <a
          href={`https://etherscan.io/address/${contributor.address}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
        >
          {shortenAddress(contributor.address)}
        </a>
      </td>
      <td>{contributor.totalContributed.toLocaleString()} BCREDS</td>
    </tr>
  ))}
                        <tr>
                          <td colSpan="2" className="text-center">
                         
                          </td>
  <td colSpan="1" className="text-left">
                            <strong>Total:</strong> {poolContributors.reduce((acc, curr) => acc + curr.totalContributed, 0).toLocaleString()} BCREDS
                          </td>                          
                        </tr>
                  </tbody>
                </Table>
                )
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    ) : (
      <p>No batches found.</p>
    )}
  </Card.Body>
</Card>

</Tab> 


            <Tab eventKey="nfts" title={`Brains (${brainTokens.length})`}>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <Card.Title className="fw-bold text-white mb-4">Total Brains ({filteredNFTs.length})</Card.Title>
 <Form.Control
  type="text"
  placeholder="Search Tokens..."
  className="mb-3 custom-form-control"
  value={nftSearch}
  onChange={(e) => setNftSearch(e.target.value)}
/>

                  {loadingTokens ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="white" />
                      <p className="mt-2 text-white">Loading token holders...</p>
                    </div>
                  ) : filteredNFTs.length > 0 ? (
                    filteredNFTs.reverse().map((token) => {
                      const isPooled = pooledTokenIds.has(token.tokenId);
                      return (
 <div key={token.tokenId} className="mb-4">
                     <div>
                 


        <div className="d-flex gap-2 badge-container mb-4">
  <>
    <h5 className="mb-0">Brain NFT #{token.tokenId}</h5>
    <div className="d-flex gap-2">
      {isPooled ? (
        <Badge pill bg="warning" text="light">Pooled Mint</Badge>
      ) : (
        <Badge pill  bg="success" text="light">Solo Mint</Badge>
      )}
      {token.owner === '0x8CAF5b4cb38BA53EECAa45Be4Ef9Cd9D7C0cc057' && (
        <Badge  pill bg="secondary" text="light" className="ms-2">BasedAI Team</Badge>
      )}
      {token.owner === '0xE14767042159E5BD2bf16F81A0FE387ab153Fbb4' && (
        <Badge  pill bg="secondary" text="light" className="ms-2">The Legend FBB4</Badge>
      )}
    </div>
  </>
</div>


                      <p>      
                            <a
                  href={`https://etherscan.io/address/${token.owner }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-info fw-bold"
                >
                  {shortenAddress(token.owner)}
                </a>
</p>


                          </div>
                       <p>
                            <a
                              href={`https://opensea.io/assets/ethereum/${CONTRACT_ADDRESS}/${token.tokenId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary"
                            >
                              View NFT on OpenSea
                            </a>
                          </p>
                         
                          <hr />
                        </div>

                      );
                    })
                    ) : (
                    <p>No NFTs found.</p>
                    )}
                  </Card.Body>
                  </Card>
                </Tab> 



                </Tabs>
              </Container>

              
          <Modal show={modalVisible} onHide={() => setModalVisible(false)} size="lg" centered>
            <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title>
              #{selectedToken} ERC-20 Tokens
                </Modal.Title>
              
                </Modal.Header>
                
                <Modal.Body>

            {loadingHolders ? ( // Show loader while fetching
      <div className="text-center">
        <Spinner animation="border" variant="white" />
        <p className="mt-2 text-white">Loading holders...</p>
      </div>
    ) : tokenHolders && tokenHolders?.holders?.length > 0 ? (
                <>
                  <p className="text-white">
              C/A: {ERC20Address}
              <a href={`https://etherscan.io/token/${ERC20Address}#balances`} target="_blank" rel="noopener noreferrer" className="ms-2">
                <br/>View on Etherscan
              </a>
            </p>                  
        {tokenHolders.holders
          .sort((a, b) => b.percentage - a.percentage) // Sort by percentage (descending)
          .map((holder, idx) => (
            <div key={idx} className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <a
                  href={`https://etherscan.io/address/${holder.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-info fw-bold"
                >
                  {shortenAddress(holder.address)}
                </a>
                <span className="text-white">
                  <strong>{holder.percentage}%</strong>
                </span>
              </div>
              <ProgressBar
                now={parseFloat(holder.percentage)}
                variant="success"
                className="mt-2"
                style={{ height: '0.5rem' }}
              />
              
              <p className="text-white mt-2">
                <strong>Balance:</strong> {holder.balance.toLocaleString()} tokens
              </p>
            </div>
          ))}

        {/* Summary Row */}
<div className="border-top pt-3 mt-4">
  <div className="d-flex justify-content-between align-items-center">
    <h5 className="fw-bold text-white">Distribution (excluding unclaimed)</h5>
    <span className="text-white">
      <strong>100%</strong>
    </span>
  </div>
  <ProgressBar
    now={100}
    label="100%"
    variant="info"
    className="mt-2"
  />
  <p className="text-white mt-2">
    <strong>Total Balance:</strong> {tokenHolders.totalSupply.toLocaleString()} tokens
  </p>
  {1000000 - tokenHolders.totalSupply > 0 && (
    <p className="text-danger mt-2">
      <strong>Unclaimed Tokens:</strong> {(1000000 - tokenHolders.totalSupply).toLocaleString()} tokens
    </p>
  )}
</div>

      </>
    ) : (
      <p>Brain NFT not yet activated so no ERC-20 tokens to show</p>
    )}
  </Modal.Body>
</Modal>



        <footer className="bg-white text-center py-3 mt-auto shadow-sm">
          <p className="mb-0">
            Follow {' '}
            <a href="https://x.com/basedbrains_xyz" target="_blank" rel="noopener noreferrer" className="text-primary">
              @basedbrains_xyz
            </a> on X
          </p>
        </footer>
      </Container>
    </div>
  );
};

export default App;
